import { useQuery } from "@apollo/react-hooks";
import { Card, Select, Space, Typography } from "antd";
import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";

import { LIST_WAREHOUSES } from "../../graphql/warehouse";

const { Paragraph } = Typography;
const { Option } = Select;

const SelectWarehouse = (props) => {
  const { data } = useQuery(LIST_WAREHOUSES);
  const warehouses = data?.listWarehouses?.items || [];

  const { onChange } = props;

  const [selectedWarehouse, setSelectedWarehouse] = useState();
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Card
        title="Mmucho Warehouse"
        extra={
          <Select
            bordered={false}
            onChange={(id) => {
              setSelectedWarehouse(id);
              onChange && onChange(id);
            }}
            placeholder="Select Warehouse"
          >
            {warehouses.map((warehouse) => {
              const { id, country } = warehouse;
              return (
                <Option key={id} value={id}>
                  <Space>
                    <ReactCountryFlag countryCode={country} svg />
                    {country}
                  </Space>
                </Option>
              );
            })}
          </Select>
        }
      >
        {selectedWarehouse &&
          warehouses.map((warehouse) => {
            const { address, id, country } = warehouse;
            if (id === selectedWarehouse)
              return (
                <React.Fragment key={id}>
                  <Paragraph strong>
                    <Space>
                      <ReactCountryFlag countryCode={country} svg />
                      {country}
                    </Space>
                  </Paragraph>
                  <Paragraph copyable>{address}</Paragraph>
                </React.Fragment>
              );
            else return null;
          })}
      </Card>
    </Space>
  );
};

export default SelectWarehouse;
