import { InfoCircleOutlined } from "@ant-design/icons";
import Alert from "antd/lib/alert";
import Card from "antd/lib/card";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Table from "antd/lib/table";
import Typography from "antd/lib/typography";
import { navigate } from "gatsby";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ResponsiveTitle from "../../components/ResponsiveTitle";
import {
  selectCurrentExchangeRates,
  selectPreferredCurrency,
} from "../../state/app";
import { formatCurrency } from "../../utils/helpers";
import CartTable from "./CartTable";
import CheckoutForm from "./CheckoutForm";
import SelectWarehouse from "./SelectWarehouse";

const { Text } = Typography;

const columns = [
  { key: "title", dataIndex: "title" },
  { key: "price", dataIndex: "price", align: "right" },
];

const PaymentConfirm = (props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const rates = useSelector(selectCurrentExchangeRates);
  const preferredCurrency = useSelector(selectPreferredCurrency);

  const merch = formatCurrency(
    props.merch / rates[props.currency],
    preferredCurrency,
    language
  );
  const fees = formatCurrency(
    props.paymentFee / rates[props.currency],
    preferredCurrency,
    language
  );
  const total = formatCurrency(
    props.total / rates[props.currency],
    preferredCurrency,
    language
  );

  const orderSummary = [
    { key: "merch", title: t("cart.merchSubtotal"), price: merch },
    { key: "fees", title: t("cart.paymentFees"), price: fees },
    { key: "total", title: t("cart.total"), price: total },
  ];

  return (
    <>
      <CartTable data={props.data} />
      <Table
        showHeader={false}
        pagination={false}
        columns={columns.map((column) => {
          const { key, dataIndex, title, align } = column;
          const renderTitle = t(title);
          return { key, dataIndex, title: renderTitle, align };
        })}
        dataSource={orderSummary}
        footer={() => (
          <Text>
            {t("checkout.footnote")}{" "}
            <a
              href="https://stripe.com/en-sg/pricing"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://stripe.com/en-sg/pricing
            </a>
          </Text>
        )}
      />
    </>
  );
};

const Checkout = () => {
  const { t } = useTranslation();
  const cartItems = useSelector((state) => state.cart);
  const [warehouseId, setWarehouseId] = useState(null);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <ResponsiveTitle>
        {t("checkout.shippingDestinationWarehouse")}
      </ResponsiveTitle>
      <Row justify="center">
        <Col style={{ maxWidth: 600 }}>
          <Card>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <Alert
                message={
                  <Text strong>
                    <InfoCircleOutlined /> {t("checkout.pleaseNoteTitle")}
                  </Text>
                }
                description={t("checkout.pleaseNoteText")}
              />
            </Space>
            <Row justify="center">
              <Col>
                <SelectWarehouse onChange={(id) => setWarehouseId(id)} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {warehouseId && (
        <>
          <Divider />
          <PaymentSection data={cartItems} warehouseId={warehouseId} />
        </>
      )}
    </Space>
  );
};

const PaymentSection = ({ data, warehouseId }) => {
  const { t } = useTranslation();

  const rates = useSelector(selectCurrentExchangeRates);

  const currency = _.get(data, "0.currency");

  const merch = data
    .map((card) => card.price * card.quantity)
    .reduce((a, b) => a + b, 0);

  const stripeRate = 3.4 / 100;
  const stripeFlat = (50 * rates[currency]) / rates["SGD"];

  const stripeFee = merch * stripeRate + stripeFlat;

  const total = merch + stripeFee;

  return (
    <Card title={t("checkout.ordersSummary")}>
      <Row gutter={25}>
        <Col md={14}>
          <PaymentConfirm
            data={data}
            currency={currency}
            merch={merch}
            paymentFee={stripeFee}
            total={total}
            warehouseId={warehouseId}
          />
        </Col>
        <Col flex="auto">
          <CheckoutForm
            buttonLabel={total}
            currency={currency}
            data={data}
            fee={total}
            warehouseId={warehouseId}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default () => {
  const cartItems = useSelector((state) => state.cart);
  const isCartEmpty = cartItems.length === 0;

  useEffect(() => {
    if (isCartEmpty) navigate("/app/cart");
  }, [isCartEmpty]);

  if (isCartEmpty) return null;

  return <Checkout />;
};
