import { gql } from "apollo-boost";

export const LIST_WAREHOUSES = gql`
  query ListWarehouses(
    $filter: ModelWarehouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWarehouses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        country
        address
      }
      nextToken
    }
  }
`;
