import { CardElement, Elements, useStripe } from "@stripe/react-stripe-js";
import { navigate } from "gatsby";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import stripePromise from "../../services/stripe";
import { listPaymentMethods } from "../../state/wallet";
import * as API from "../../utils/api";
import NewCard from "../profile/wallet/NewCard";
import PaymentMethods from "../profile/wallet/PaymentMethods";

const createOptions = () => {
  return {
    style: {
      base: {
        color: "#424770",
        fontFamily: "Roboto, Open Sans, sans-serif",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };
};

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const walletData = useSelector((state) => state.wallet);

  const [checked, setChecked] = useState(false);
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [newCard, setNewCard] = useState(false);
  const [walletId, setWalletId] = useState("");

  useEffect(() => {
    dispatch(listPaymentMethods());
  }, [dispatch]);

  const data = props.consolidationCheckout
    ? props.data
    : props.data.map((lineItem) => {
        const {
          currency,
          images,
          name,
          platform,
          price,
          productId,
          quantity,
          seller,
          sellerLink,
          skuId,
          variants,
        } = lineItem;
        return {
          skuId,
          productId,
          name,
          variants,
          currency,
          quantity,
          seller,
          sellerLink,

          unitPrice: price,
          sellerPlatform: platform,
          amount: price * quantity,
          thumbnail: images.length > 0 && images[0],
        };
      });

  const warehouseId = props.consolidationCheckout
    ? _.get(data, "0.warehouseId")
    : props.warehouseId;

  const currency = props.consolidationCheckout
    ? props.currency
    : _.get(data, "0.currency");

  const fee = Math.ceil(props.fee);

  const shippingMethod = props.selectedShippingMethod;

  const oldAddress = props.address || {};
  const shippingAddress = {
    address: {
      city: oldAddress.city || null,
      country: oldAddress.country || null,
      line1: oldAddress.address_line_1 || null,
      line2: oldAddress.address_line_2 || null,
      postcode: oldAddress.postcode || null,
    },
    name: oldAddress.name,
    phone: oldAddress.contact,
  };

  const resetCheckout = (e) => {
    setSubmitting(true);
    setError("");
  };

  const getPaymentMethod = async (shouldUseNewCard) => {
    if (walletId) return walletId;
    else if (shouldUseNewCard) {
      const { paymentMethod, error } = await stripe.createPaymentMethod("card");
      if (error) throw error;
      return paymentMethod;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (stripe) {
      resetCheckout();
      try {
        const paymentMethod = await getPaymentMethod(true);

        if (props.consolidationCheckout) {
          const consolidationOrderData = {
            paymentMethod,
            amount: fee,
            currency: currency,
            savePaymentMethod: checked,
            shipping: shippingAddress,
            warehouseId,
            shippingMethod,
            orders: data,
          };

          const consolidationOrder = await API.shippingCheckout(
            consolidationOrderData
          );
          setSubmitting(false);
          navigate("/app/checkout/success", {
            state: {
              order: consolidationOrder,
              delivery: true,
            },
          });
        } else {
          const orderData = {
            paymentMethod,
            amount: fee,
            currency: currency,
            savePaymentMethod: checked,
            lineItems: data,
            warehouseId,
          };
          const order = await API.orderCheckout(orderData);
          setSubmitting(false);
          navigate("/app/checkout/success", {
            state: {
              order: order,
              shouldResetCart: true,
            },
          });
        }
      } catch (err) {
        setError(err.message);
      }
    } else setError("Stripe not Loaded.");
  };

  const handleChecked = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      {!newCard && walletData.length > 0 ? (
        <PaymentMethods
          setWalletId={(e) => setWalletId(e)}
          setNewCard={(e) => setNewCard(e)}
          submitting={submitting}
          fee={fee}
          currency={currency}
        />
      ) : (
        <NewCard
          handleChecked={(e) => handleChecked(e)}
          setNewCard={(e) => setNewCard(e)}
          submitting={submitting}
          error={error}
          currency={currency}
          fee={fee}
        >
          <CardElement hidePostalCode={true} {...createOptions()} />
        </NewCard>
      )}
    </form>
  );
};

export default (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);
