import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Checkbox from "antd/lib/checkbox";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  selectCurrentExchangeRates,
  selectPreferredCurrency,
} from "../../../state/app";
import { formatCurrency } from "../../../utils/helpers";
import styles from "./css/stripe.module.scss";

const { Text } = Typography;

const NewCard = (props) => {
  const {
    setNewCard,
    children,
    fee,
    currency,
    handleChecked,
    error,
    submitting,
  } = props;

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const walletData = useSelector((state) => {
    return state.wallet;
  });

  const rates = useSelector(selectCurrentExchangeRates);
  const preferredCurrency = useSelector(selectPreferredCurrency);

  return (
    <Card title={t("checkout.form.useNewCard")}>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {walletData.length > 0 && (
          <Button type="link" onClick={() => setNewCard(false)} size="large">
            {`< ${t("checkout.form.back")}`}
          </Button>
        )}
        <div className={styles.cardElement}>{children}</div>
        <Row justify="center">
          <Text type="error">{error}</Text>
        </Row>
        <Checkbox onChange={(e) => handleChecked(e)}>
          {t("checkout.form.save")}
        </Checkbox>
        <Button
          block
          type="primary"
          htmlType="submit"
          size="large"
          loading={submitting}
        >
          {t("checkout.form.pay")}{" "}
          {formatCurrency(fee / rates[currency], preferredCurrency)}
        </Button>
      </Space>
    </Card>
  );
};

export default NewCard;
