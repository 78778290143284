import { Button, Select } from "antd";
import { Link } from "gatsby";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  selectExchangeRates,
  selectPreferredCurrency,
} from "../../../state/app";
import { listPaymentMethods } from "../../../state/wallet";
import { formatCurrency } from "../../../utils/helpers";
import CreditCard from "./CreditCard";
import styles from "./css/paymentmethods.module.scss";

const { Option } = Select;

const PaymentMethods = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listPaymentMethods());
  }, [dispatch]);

  const paymentMethods = useSelector((state) => state.wallet);

  const rates = useSelector(selectExchangeRates);
  const preferredCurrency = useSelector(selectPreferredCurrency);

  const fee = formatCurrency(
    props.fee / rates[props.currency],
    preferredCurrency
  );

  const [paymentMethod, setSelectedPaymentMethod] = useState(paymentMethods[0]);

  const selectPayment = useCallback(
    (id) => {
      const paymentMethod = _.find(paymentMethods, { id });
      setSelectedPaymentMethod(paymentMethod);
      props.setWalletId(paymentMethod);
    },
    [paymentMethods, props]
  );

  useEffect(() => {
    dispatch(listPaymentMethods());
  }, [dispatch]);

  useEffect(() => {
    if (paymentMethods.length) {
      const [defaultPayment] = paymentMethods;
      selectPayment(defaultPayment.id);
    }
  }, [paymentMethods, selectPayment]);

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t("checkout.form.payment")}</h3>
      <Select
        value={`**** **** **** ${paymentMethod?.card?.last4}`}
        onChange={(value) => selectPayment(value)}
        className={styles.select}
      >
        {paymentMethods.map((paymentMethod) => {
          return (
            <Option key={paymentMethod.id} value={paymentMethod.id}>
              {paymentMethod.name} **** **** **** {paymentMethod.card.last4}
            </Option>
          );
        })}
      </Select>
      <Link to="app/walletmanager" className={styles.edit}>
        {t("checkout.form.edit")}
      </Link>
      {paymentMethod && (
        <CreditCard buttonDisplay={false} wallet={paymentMethod} />
      )}
      <button onClick={() => props.setNewCard(true)} className={styles.add}>
        + {t("checkout.form.add")}
      </button>

      <Button
        block
        type="primary"
        size="large"
        htmlType="submit"
        loading={props.submitting}
      >
        {props.submitting ? (
          t("checkout.form.paying")
        ) : (
          <>
            {t("checkout.form.pay")} <>{fee.result} </>
            <>{fee.currency}</>
          </>
        )}
      </Button>
    </div>
  );
};

export default PaymentMethods;
